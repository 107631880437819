import {
  Component,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  HostListener
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../services/layout.service';
import {Subscription} from 'rxjs';
import {ConfigService} from '../services/config.service';
import {FormControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {LISTITEMS} from '../data/template-search';
import {Router} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {LocalStorageService} from '../storage/local-storage.service';
import {PULSERIGHT_ROUTES} from '../vertical-menu/vertical-pulseright-menu-routes.config';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {Patient} from '../../pages/full-pages/patients/list-patients/data/users-list.data';
import {QueryParamsServiceService} from '../query-params/query-params-service.service';

const moment = require('moment');


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = 'en';
  selectedLanguageText = 'English';
  selectedLanguageFlag = './assets/img/flags/us.png';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = '';
  checkType = 'Check-In';
  transparentBGClass = '';
  hideSidebar = true;
  userCheckStatus = false;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  appointmentList: any[] = [];
  selectedOrgGroup: any;


  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new UntypedFormControl();

  userData: any = {
    auth: {
      data: {}
    },
    organizationGroupList: [],
    doctorList: [],
    SelectedOrg: '',
    SelectedOrgGrp: '',
    patientList: [],
    StaffList: [],
    serviceList: [],
    dashboard: {},
    appointmentList: [],
    managerDashboard: {},
    timeOffList: [],
    overTime: [],
  };

  public organizationList: any = [];
  pharmacyList = [];
  displayCheckIn = false;

  public config: any = {};
  disabled = false;
  today = new Date();
  file = null;
  closeResult = '';
  user: Patient;

  // Update of Password..
  updateForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.pattern('^(?!\\s).*$')]),
    confrimPassword: new FormControl('', [Validators.required, Validators.pattern('^(?!\\s).*$')])
  });
  OnformSubmitted = false;
  defaultSelectedOrg = '';

  editForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    gender: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]),
  })
  orgGrpList: any[] = [];
  organizationGrp: any;
  profileImage = 'assets/img/defaultProfile.jpg';
  toggleEye = {
    newPassword: false,
    confirmPassword: false
  }

  constructor(public translate: TranslateService,
              private layoutService: LayoutService,
              private router: Router,
              private modalService: NgbModal,
              private configService: ConfigService,
              private cdr: ChangeDetectorRef,
              private auth: AuthService,
              private toastr: ToastrService,private queryParams: QueryParamsServiceService,
              private storage: LocalStorageService) {

    const browserLang: string = translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|es|kn|ta|te|pt|de/) ? browserLang : 'en');
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    // this.orgGrpList = JSON.parse(localStorage.getItem('OrganizationGroupList'));
    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });
  }

  async ngOnInit() {
    await this.storage.getData('pulse-right').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    // Watch storage for changes
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    this.organizationGrp = JSON.parse(localStorage.getItem('organizationGroup')).data;
    if (this.userData.auth.data.userType === 'Pharmacy Admin') {
      this.auth.fetchDoctor(this.userData, this.userData.auth.data).then(async (patient: any) => {
        this.user = patient.result;
        this.organizationList = this.userData.auth.data.organization;
        this.auth.fetch_organization_Id(this.userData, this.userData.SelectedOrg).then((organization: any) => {
          const root = document.documentElement;
          root.style.setProperty('--primary-color-organization', organization.primaryColor);
          root.style.setProperty('--secondary-color-organization', organization.secondaryColor);
        });
      });
      return;
    }
    if (window.location.pathname !== '/booking') {
      if (this.userData.auth.data.role === 'Admin' || this.userData.auth.data.userType === 'Doctor') {
        if (this.userData.auth.data.userType === 'Doctor') {
          this.auth.fetchUserData(this.userData).then((resp: any) => {
            this.user = resp.result;
            if (this.organizationGrp.autoLogout.doctors !== 'Never') {
              this.auth.INACTIVITY_PERIOD = Number(this.organizationGrp.autoLogout.doctors) * 60 * 1000;
            }
            this.organizationList = this.userData.auth.data.organization;
            this.auth.fetch_organization_Id(this.userData, this.userData.SelectedOrg).then((organization: any) => {
              const root = document.documentElement;
              root.style.setProperty('--primary-color-organization', organization.primaryColor);
              root.style.setProperty('--secondary-color-organization', organization.secondaryColor);
            });
          });
          return;
        }
        if (this.userData.auth.data.userType === 'Manager' || this.userData.auth.data.userType === 'Staff') {
          if (this.userData.auth.data.userType === 'Staff') {
            this.loadMyAttendence();
          }
          this.defaultSelectedOrg = localStorage.getItem('orgGroupId');
        } else {
          this.defaultSelectedOrg = localStorage.getItem('orgGroupId');
          this.organizationList = this.userData.auth.data.organization;
          const organization = this.organizationList.find(org => org.organizationId._id === this.userData.SelectedOrg);
          organization.organizationId.modulesEnabled.some(module => {
            if (module.name === 'Leave Tracker') {
              this.displayCheckIn = true;
            }
          });
          const root = document.documentElement;
          root.style.setProperty('--primary-color-organization', this.organizationList[0].organizationId.primaryColor);
          root.style.setProperty('--secondary-color-organization', this.organizationList[0].organizationId.secondaryColor);
        }
      } else {
        if (this.userData.auth.data.userType === 'Admin') {
          this.auth.fetchAll_OrgGroups(this.userData, 1).then((resp: any) => {
            this.orgGrpList = resp.results.filter((data: any) => {
              return !data.deleted;
            });
            localStorage.setItem('OrganizationGroupList', JSON.stringify(this.orgGrpList));
            this.defaultSelectedOrg = localStorage.getItem('orgGroupId');
            this.organizationList = this.orgGrpList.find(orgGrp => orgGrp._id === this.userData.SelectedOrgGrp).organization;
            this.auth.fetch_organization_Id(this.userData, this.userData.SelectedOrg).then((organization: any) => {
              const root = document.documentElement;
              root.style.setProperty('--primary-color-organization', organization.primaryColor);
              root.style.setProperty('--secondary-color-organization', organization.secondaryColor);
            });
          });
        }
      }
      this.auth.fetchUserData(this.userData).then((resp: any) => {
        this.user = resp.result;
        if (this.user.role !== 'Super Admin') {
          this.organizationList = this.user.organization;
          this.auth.fetch_organization_Id(this.userData, this.userData.SelectedOrg).then((organization: any) => {
            const root = document.documentElement;
            root.style.setProperty('--primary-color-organization', organization.primaryColor);
            root.style.setProperty('--secondary-color-organization', organization.secondaryColor);
          });
        }
        if (this.user.userType === 'Manager') {
          if (this.organizationGrp.autoLogout.manager !== 'Never') {
            this.auth.INACTIVITY_PERIOD = Number(this.organizationGrp.autoLogout.manager) * 60 * 1000;
          } else {
            this.auth.INACTIVITY_PERIOD = 24 * 60 * 60 * 1000;
          }
        }
        if (this.user.userType === 'Staff') {
          if (this.organizationGrp.autoLogout.staff !== 'Never') {
            this.auth.INACTIVITY_PERIOD = Number(this.organizationGrp.autoLogout.staff) * 60 * 1000;
          } else {
            this.auth.INACTIVITY_PERIOD = 24 * 60 * 60 * 1000;
          }
        }
        if (this.user.role === 'Super Admin') {
          this.auth.INACTIVITY_PERIOD = 24 * 60 * 60 * 1000;
        }
      });
    }

    if (window.location.pathname !== '/booking') {
      this.auth.orgGrpSubject.subscribe(data => {
        if (data === 'loadGroups') {
          this.loadOrgGrps();
        }
      });
    }
  }

  loadOrgGrps() {
    this.auth.fetchAll_OrgGroups(this.userData, 1).then((resp: any) => {
      this.orgGrpList = resp.results.filter((data: any) => {
        return !data.deleted;
      });
      localStorage.setItem('OrganizationGroupList', JSON.stringify(this.orgGrpList));
      this.defaultSelectedOrg = localStorage.getItem('orgGroupId');
    });
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Define onload event handler
      reader.onload = () => {
        const result = reader.result;
        if (typeof result === 'string') {
          resolve(result);
        } else {
          reject(new Error('Failed to convert file to base64'));
        }
      };

      // Define onerror event handler
      reader.onerror = (error) => {
        reject(error);
      };

      // Read file as data URL
      reader.readAsDataURL(file);
    });
  }

  loadMyAttendence() {
    this.auth.fetchAttendance_userId(this.userData, this.userData.auth.data).then((resp: any) => {

      if (resp.status === 404) {
        return;
      }
      this.userCheckStatus = resp.checkedIn;
      if (this.userCheckStatus) {
        this.checkType = 'Check-Out';
      } else {
        this.checkType = 'Check-In';
      }
    });
  }


  OnSubmit(content) {
    this.OnformSubmitted = true;
    if (this.updateForm.value.password !== this.updateForm.value.confrimPassword) {
      this.toastr.clear();
      this.toastr.error('Password is mismatch!', 'Message!', {
        positionClass: 'toast-top-center'
      });
      return;
    } else if (this.updateForm.invalid) {
      return;
    }
    const updateObj = [];
    if (this.updateForm.controls.password.dirty) {
      updateObj.push({propName: 'password', value: this.updateForm.value.password})
    }
    this.auth.ChangePassword(updateObj, this.userData).then((res) => {
      this.toastr.success('Password changed successfully', 'Message!', {
        positionClass: 'toast-top-center'
      });
      this.modalService.dismissAll();
      this.clear();
      this.auth.logout();
      this.router.navigate(['/login-admin']);
    }).catch((err) => {
      if (err) {
        this.toastr.clear();
        this.toastr.error('Internal Server Error', 'Message!', {
          positionClass: 'toast-top-center'
        });
      }
    })
  }


  clear() {
    this.updateForm.reset();
    this.OnformSubmitted = false;
  }

  get lf() {
    return this.updateForm.controls;
  }


  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  async refreshData() {
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === 'Light') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = '';
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === '') {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue('');
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      const url = this.searchResults.first.url;
      if (url && url !== '') {
        this.control.setValue('');
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === 'en') {
      this.selectedLanguageText = 'English';
      this.selectedLanguageFlag = './assets/img/flags/us.png';
    } else if (language === 'es') {
      this.selectedLanguageText = 'Spanish';
      this.selectedLanguageFlag = './assets/img/flags/es.png';
    } else if (language === 'pt') {
      this.selectedLanguageText = 'Portuguese';
      this.selectedLanguageFlag = './assets/img/flags/pt.png';
    } else if (language === 'de') {
      this.selectedLanguageText = 'German';
      this.selectedLanguageFlag = './assets/img/flags/de.png';
    }
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue('');
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);


  }


  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  logout() {
    if (this.userData.auth.data.userType === 'Staff') {
      if (this.userCheckStatus) {
        this.auth.creatAttendance(this.userData, this.userData.SelectedOrg, 'checked-Out', {}).then((response: any) => {
          if (response.status === 400) {
            this.toastr.clear();
            this.toastr.error(response.error.error, 'Error!', {
              positionClass: 'toast-top-center'
            });
          } else {
            this.loadMyAttendence();
          }
        })
      }
    }
    this.auth.logout();
    this.router.navigate(['/login-admin']);
    localStorage.removeItem('pulse-right');
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.go(1);
    }
  }

  async OnChangeOrgGrp(event: any) {
    this.selectedOrgGroup = event.target.value;
    this.defaultSelectedOrg = event.target.value;
    this.queryParams.setParams({});
    localStorage.setItem('orgGroupId', event.target.value);
    this.organizationList = [];
    this.organizationList = JSON.parse(localStorage.getItem('OrganizationGroupList')).find(orgGrp => orgGrp._id === event.target.value).organization;
    localStorage.setItem('organization', this.organizationList[0]._id);
    if (this.organizationList.length > 0) {
      this.auth.menuLoader.next('OrgDetails');
      await this.auth.SelectedOrgGrpId(event.target.value, this.organizationList[0]._id);
      const root = document.documentElement;
      this.auth.fetch_organization_Id(this.userData, this.organizationList[0]._id).then((response: any) => {
        root.style.setProperty('--primary-color-organization', response.primaryColor);
        root.style.setProperty('--secondary-color-organization', response.secondaryColor);
      });
      await this.auth.dashboard_stats(this.organizationList[0]._id).then(() => {
      });
      const startDate = moment().startOf('month').format();
      const endDate = moment().endOf('month').format();
      this.auth.filterType = 'Month';
      this.auth.filterType1 = 'Month';
      await this.auth.dashboard_paitentBill_statistics(this.userData, startDate, endDate).then((response: any) => {
        this.auth.totalRevenueGenerated = response.totalRevenueGenerated;
      });
      await this.auth.dashboard_paitentBill_statistics(this.userData, startDate, endDate, 'pharmacy').then((response: any) => {
        this.auth.PharmacyRevenueGenerated = response.totalRevenueGenerated;
      });
      if (this.userData.auth.data.userType === 'Admin' || this.userData.auth.data.userType === 'Manager') {
        await this.auth.number_of_checkIns_CheckOuts(this.userData, this.organizationList[0]._id, moment(new Date()).format()).then(() => {
        });
      }
      await this.auth.bookingCount(this.userData, this.organizationList[0]._id).then((response) => {
        this.auth.todayapts = response.bookingsToday;
        this.auth.yerterDayapts = response.bookingsYesterday;
        this.auth.tommorrowApts = response.bookingsTomorrow;
        this.auth.tillMonthApts = response.bookingsThisMonth;
      });
      await this.router.navigate(['/dashboard']);
    }
  }

  async OnChangeOrg(event) {
    localStorage.setItem('organization', event.target.value);
    this.queryParams.setParams({});
    if (this.userData.auth.data.userType === 'Doctor') {
      this.auth.getSelectedOrgId(event.target.value);
      this.auth.fetch_organization_Id(this.userData, event.target.value).then((organization: any) => {
        const root = document.documentElement;
        this.auth.menuLoader.next('OrgDetails');
        this.auth.pharmacyRefresh.next('doctor');
        root.style.setProperty('--primary-color-organization', organization.primaryColor);
        root.style.setProperty('--secondary-color-organization', organization.secondaryColor);
      });
      return;
    }
    if (this.userData.auth.data.userType !== 'Admin') {
      this.auth.getSelectedOrgId(event.target.value);
      this.auth.menuLoader.next('OrgDetails');
      this.auth.callOrganization.next(event.target.value);
      await this.auth.dashboard_stats(this.userData.SelectedOrg).then(() => {
      });
      if (this.userData.auth.data.userType === 'Manager') {
        this.auth.number_of_checkIns_CheckOuts(this.userData, this.userData.SelectedOrg, moment(new Date()).format()).then(() => {
        })
      }
      const org = this.organizationList.find(org => org._id === this.userData.SelectedOrg);
      this.auth.fetch_organization_Id(this.userData, event.target.value).then((response: any) => {
        const root = document.documentElement;
        root.style.setProperty('--primary-color-organization', response.primaryColor);
        root.style.setProperty('--secondary-color-organization', response.secondaryColor);
        response.modulesEnabled.some((module: any) => {
          if (module.name === 'Leave Tracker') {
            this.displayCheckIn = true;
          } else {
            this.displayCheckIn = false;
          }
        });
      })
      await this.auth.bookingCount(this.userData, event.target.value).then((response) => {
        this.auth.todayapts = response.bookingsToday;
        this.auth.yerterDayapts = response.bookingsYesterday;
        this.auth.tommorrowApts = response.bookingsTomorrow;
        this.auth.tillMonthApts = response.bookingsThisMonth;
      });
      const startDate = moment().startOf('month').format();
      const endDate = moment().endOf('month').format();
      this.auth.filterType = 'Month';
      this.auth.filterType1 = 'Month';
      await this.auth.dashboard_paitentBill_statistics(this.userData, startDate, endDate).then((response: any) => {
        this.auth.totalRevenueGenerated = response.totalRevenueGenerated;
      });
      await this.auth.dashboard_paitentBill_statistics(this.userData, startDate, endDate, 'pharmacy').then((response: any) => {
        this.auth.PharmacyRevenueGenerated = response.totalRevenueGenerated;
      });
      this.router.navigate(['/dashboard']);
      return;
    }
  }


  async OnChangeOrganization_Box(event) {
    localStorage.setItem('organization', event.target.value);
    this.queryParams.setParams({});
    this.auth.callOrganization.next(event.target.value);
    this.auth.getSelectedOrgId(event.target.value);
    this.auth.menuLoader.next('OrgDetails');
    this.auth.fetch_organization_Id(this.userData, event.target.value).then((response: any) => {
      const root = document.documentElement;
      root.style.setProperty('--primary-color-organization', response.primaryColor);
      root.style.setProperty('--secondary-color-organization', response.secondaryColor);
    })
    await this.auth.dashboard_stats(this.userData.SelectedOrg).then(() => {
    });
    this.auth.number_of_checkIns_CheckOuts(this.userData, this.userData.SelectedOrg, moment(new Date()).format()).then(() => {
    })
    await this.auth.bookingCount(this.userData, event.target.value).then((response) => {
      this.auth.todayapts = response.bookingsToday;
      this.auth.yerterDayapts = response.bookingsYesterday;
      this.auth.tommorrowApts = response.bookingsTomorrow;
      this.auth.tillMonthApts = response.bookingsThisMonth;
    });
    const startDate = moment().startOf('month').format();
    const endDate = moment().endOf('month').format();
    this.auth.filterType = 'Month';
    this.auth.filterType1 = 'Month';
    await this.auth.dashboard_paitentBill_statistics(this.userData, startDate, endDate).then((response: any) => {
      this.auth.totalRevenueGenerated = response.totalRevenueGenerated;
    });
    await this.auth.dashboard_paitentBill_statistics(this.userData, startDate, endDate, 'pharmacy').then((response: any) => {
      this.auth.PharmacyRevenueGenerated = response.totalRevenueGenerated;
    });
    this.router.navigate(['/dashboard']);
  }


  open(content, size) {
    this.toggleEye = {
      newPassword: false,
      confirmPassword: false
    }
    this.OnformSubmitted = false;
    this.updateForm.reset();
    if (this.userData) {
      this.auth.fetchUserData(this.userData).then((resp: any) => {
        this.editForm.controls['name'].setValue(resp.result.name)
        this.editForm.controls['email'].setValue(resp.result.email)
        this.editForm.controls['number'].setValue(resp.result.number.split('+91')[1])
        this.editForm.controls['gender'].setValue(resp.result.gender);
        this.user = resp.result;
        this.profileImage = this.user.avatar !== null ? this.user.avatar : this.profileImage;
      })
    }
    this.modalService.open(content, {size: size, backdrop: 'static'});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async autoRefresh() {
    this.storage.getData('pulse-right').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
      ;
    });
    // Watch storage for changes
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    await this.router.navigate(['/dashboard'])
  }


  async upload(file: any) {
    this.file = file.target.files[0];
    this.profileImage = await this.fileToBase64(this.file);
  }


  EditProfileDetails(modal: any) {
    this.OnformSubmitted = true;
    if (this.editForm.invalid) {
      return;
    }
    const body = [];
    for (const key in this.editForm.controls) {
      if (key === 'number' && this.editForm.controls[key].dirty) {
        body.push({
          propName: key, value: '+91' + this.editForm.controls[key].value
        })
      } else if (this.editForm.controls[key].dirty) {
        body.push({
          propName: key, value: this.editForm.controls[key].value
        })
      }
    }

    if (this.file !== null) {
      this.auth.addProfilePic(this.userData, this.file, this.userData.auth.data).then((resp: any) => {
        this.user = resp;
        this.toastr.success('Profile Picture Updated successfully', 'Message!', {
          positionClass: 'toast-top-center'
        });
        this.file = null;
      })
    }
    if (body.length > 0) {
      this.auth.editPatient(body, this.userData, this.user._id).then((resp: any) => {
        if (resp.status === 500) {
          this.toastr.error('Failed to update the Profile', 'Error!', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastr.success('Profile Updated successfully', 'Message!', {
            positionClass: 'toast-top-center'
          });
          this.user = resp;
        }
      }).catch(err => {
      });
    }
    modal.dismiss('Cross-Click');
  }


  checkIn_out() {
    const body = {}
    if (this.userCheckStatus) {
      this.auth.creatAttendance(this.userData, this.userData.SelectedOrg, 'checked-Out', body).then((response: any) => {
        if (response.status === 400) {
          this.toastr.error(response.error.error, 'Error!', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastr.success('Checked-Out Successfully', 'Message!', {
            positionClass: 'toast-top-center'
          });
          this.loadMyAttendence();
        }
      })
    } else {
      this.auth.creatAttendance(this.userData, this.userData.SelectedOrg, 'checked-In', body).then((response: any) => {
        if (response.status === 400) {
          this.toastr.error(response.error.error, 'Error!', {
            positionClass: 'toast-top-center'
          });
        } else {
          this.toastr.success('Checked-In Successfully', 'Message!', {
            positionClass: 'toast-top-center'
          });
          this.loadMyAttendence();
        }
      })
    }
  }

  SwitchOrg(event) {
    this.auth.getSelectedOrgId(event.target.value);
    this.auth.callOrganization.next(event.target.value);
    this.auth.pharmacyRefresh.next('pharmacy');
    this.auth.fetch_organization_Id(this.userData, this.userData.SelectedOrg).then((organization: any) => {
      const root = document.documentElement;
      root.style.setProperty('--primary-color-organization', organization.primaryColor);
      root.style.setProperty('--secondary-color-organization', organization.secondaryColor);
    });
    const startDate = moment().startOf('month').format();
    const endDate = moment().endOf('month').format();
    this.auth.filterType1 = 'Month';
    this.auth.dashboard_paitentBill_statistics(this.userData, startDate, endDate, 'pharmacy').then((response: any) => {
      this.auth.PharmacyRevenueGenerated = response.totalRevenueGenerated;
    });
  }
}
